html,
body {
    width: 100%;
    height: 100%;
}

.authentication-bg {
    .auth-logo {
        .logo {
            height: 40px;
        }
    }
}
.form-label {
    sup {
        color: red;
    }
}
.breadcrumb {
    & input[type='text'] {
        background-color: white !important;
        border-color: #7b8190 !important;
        margin-right: 10px;
    }
}
.vertical-menu {
    z-index: 0;
}
#page-topbar {
    z-index: 99;
}
.modal-header {
    position: sticky;
    top: 0;
    background-color: inherit; /* [1] */
    z-index: 1055; /* [2] */
}
.modal-footer {
    position: sticky;
    bottom: 0;
    background-color: inherit; /* [1] */
    z-index: 1055; /* [2] */
}

.badge-danger {
    color: #fff;
    background-color: #dc3545 !important;
    padding: 0.25em 0.4em !important;
}
.react-form-builder-form .rfb-item label span {
    padding: 0;
}
.fa-disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
    pointer-events: none;
}
.fa-enabled {
    cursor: pointer;
}
.fa-danger {
    color: red;
}
.ant-table-content {
    .ant-form-item {
        margin-bottom: 0;
    }
}
.custom-control-label {
    margin-left: 0.25rem;
}
.process-step-toolbar-submenu {
    li.ant-menu-item {
        height: auto !important;
        padding: 0 !important;
    }
}

.process-step-toolbar {
    .ant-menu-vertical {
        background-color: transparent !important;
        border: none !important;
        width: 100%;
    }
}
.pointer-none {
    pointer-events: none;
}
