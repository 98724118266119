.modal {
    --bs-modal-width: 80% !important;
    --bs-modal-height: 50% !important;
}

.modal-content {
    max-height: 80vh;
    overflow-y: auto;
}

.Mui-expanded {
    margin: 0 !important;
}

/* react form builder 2 toolbar & preview area */
.react-form-builder > div:nth-of-type(1) {
    display: flex !important;
    flex-direction: row !important;
    max-height: 100%;
}

.form-place-holder {
    height: 100px !important;
}
.form-place-holder-hidden {
    height: 100px !important;
}

.react-form-builder-toolbar ul li {
    margin: 2px !important;
    padding: 1px !important;
}
.react-form-builder-toolbar {
    height: 79vh;
    width: 20% !important;
    margin: 0 !important;
    order: 1 !important;
    overflow: auto;
}

.preview-container {
    height: 79vh;
    width: 80% !important;
    margin: 0 !important;
    order: 2 !important;
    overflow: hidden;
}
.react-form-builder-preview {
    height: 79vh;
    min-height: 500px !important;
    overflow: auto;
    flex: 1;
    transition: width 0.3s ease-out;
    float: left !important;
}
.react-form-builder-preview.is-editing {
    width: calc(100% - 372px);
}

/* react form builder 2 edit form */
.edit-form, .edit-extended-form {
    position: relative !important;
    transition: transform 0.3s ease-out;
    width: 360px !important;
    float: right !important;
    margin-left: 10px;
    height: 100%;
    overflow: auto;
}

// make list groups clickable on whole width
.btn-block {
    display: block;
    width: 100%;
    text-align: left !important;
}
.toolbar-group-item .btn::after {
    content: '▼';
    float: right;
    transition: transform 0.3s ease;
}
/*.toolbar-group-item .btn + .collapse.show::before {
    content: '▲';
    float: right;
    position: absolute;
    right: 20px;
}*/

// button list
.modal-content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.buttonlist button {
    margin-left: 10px;
}
.button-edit-form {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
}
.button-edit-form label {
    display: block;
    margin-bottom: 5px;
}
.button-edit-form input[type="text"],
.button-edit-form input[type="color"] {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}
.button-edit-form input[type="color"] {
    height: 40px;
}
.button-edit-form button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.highlighted-button {
    border: 2px solid #007bff;
    color: #007bff;
    font-weight: bold;
}

// table
.ant-table-cell {
    padding: 5px !important;
}
.ant-table-wrapper {
    overflow: auto !important;
    max-height: 50vh !important;
}
input[type=date] {
    width: 100% !important;
}
