.react-flow {
    --background: #fafafa;
    --panel-background: #f8fafc;
    --panel-shadow: 0 0 4px 0 rgb(0 0 0 / 0.2);
    --text: #0a0a0a;
    --color-prodyne: #172983;
    --color-bright-blue: #4096ff;

    background-color: var(--background);
}

.react-flow.dark {
    --background: #0f172a;
    --panel-background: #1e293b;
    --panel-shadow: 0 0 8px 0 rgb(0 0 0 / 0.4);
    --text: #fafafa;
}

.react-flow__panel,
.react-flow__node-toolbar {
    background-color: var(--panel-background);
    box-shadow: var(--panel-shadow);
    border-radius: 8px;
    padding: 8px;
}

.react-flow svg {
    display: block;
}

.react-flow__minimap-mask {
    fill: var(--background);
    fill-opacity: 0.85;
}

.react-flow__node-toolbar {
    display: flex;
    gap: 8px;
}

.react-flow__controls-button,
.react-flow__controls-button:hover {
    background: var(--panel-background);
    border-bottom: none;
}

.react-flow__controls-button > svg {
    fill: var(--text);
}

.react-flow__handle {
    opacity: 0;
    z-index: 10;
}

.react-flow__node.selected .react-flow__handle {
    opacity: 1;
}

.react-flow__handle,
.react-flow__resize-control.handle {
    border: 1px solid var(--background);
}

.sidebar-label {
    color: var(--text);
    font-size: 12px;
    margin-bottom: 4px;
    user-select: none;
}

.sidebar-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
}

.sidebar-item {
    /* opacity: 0.5; */
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    align-items: center;
}

.sidebar-item > svg {
    stroke: black;
}

/* .sidebar-item:hover {
    opacity: 1;
    background-color: var(--background);
} */

.sidebar-item-drag-image {
    transform: translate(0, 0);
    position: absolute;
    top: -100000px;
    left: -1000000px;
}

.color-swatch {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    outline: none;
    box-shadow: none;
    border: 1px solid var(--panel-background);
    cursor: pointer;
}

.color-swatch.active {
    outline: 2px solid var(--text);
}

.node-label {
    position: absolute;
    left: -50%;
    text-align: center;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    color: var(--text);
}

.react-flow__node:hover .react-flow__handle {
    opacity: 1;
    width: 10px;
    height: 14px;
    border-radius: 3px;
    background-color: #784be8 !important;
}

.node-label::placeholder {
    color: var(--text);
    opacity: 0.5;
}

.process-step-toolbar {
    flex-direction: column;
}

.process-step-description {
    display: block;
    width: 300px;
    word-wrap: break-word;
    text-wrap: wrap;
    line-height: normal;
}

.node-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    color: var(--text);
}

.edge-label-container {
    align-items: center;
}

.edge-label-container .node-label {
    position: static;
}

.react-flow__node-pool,
.react-flow__node-lane {
    padding: 10px;
    border-radius: 3px;
    font-size: 12px;
    color: #222;
    text-align: center;
    border-width: 2px;
    border-style: solid;
    border-color: var(--color-prodyne);
    z-index: -1 !important;
}
.react-flow__node-lane {
    border-color: var(--color-bright-blue);
}
.btn-add-pool {
    position: absolute;
    bottom: -30px;
    right: 10px;
    color: var(--color-prodyne);
}
.btn-add-lane {
    position: absolute;
    bottom: 0;
    right: 10px;
    color: var(--color-bright-blue);
}
.pool-label,
.lane-label {
    position: absolute;
    left: -14px;
    transform: translate(-50%, -50%) rotate(-90deg);
    top: 50%;
    transform-origin: center center;
    width: 8%;
    text-align: center;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    color: var(--color-prodyne);
    font-size: 24px;
}
.lane-label {
    color: var(--color-bright-blue);
}
.react-flow__node.highlight {
    background-color: rgba(255, 0, 114, 0.4);
    color: white;
}

.activity-label {
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    width: 100%;
    padding: 4px 11px;
}
.react-flow__edge-path {
    stroke-width: 2px;
    stroke: #707070;
}
.react-flow__edge.selected .react-flow__edge-path {
    stroke: red;
}
.node-label:disabled, .activity-label:disabled { 
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.25);
}
.table-wrapper {
    overflow: auto !important;
    max-height: 36vh !important;
    margin-bottom: 12px;
    margin-top: 12px;
}