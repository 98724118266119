.dnd-flow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.dnd-flow aside {
    width: 25%;
    border-left: 1px solid #EDEDED;
    font-size: 12px;
}

.info-label {
    font-weight: bold;
    margin-right: 5px;
}

.layout-container {
    overflow: auto;
    height: 100%;
}

.info-header {
    color: #000;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    border-bottom: 1px solid #EDEDED;
    padding: 0 10px;
    font-weight: bold;
}

.info-content-container {
    color: #000;
    padding: 10px;
    background-color: #fff;
}

.info-footer {
    color: #000;
    padding: 10px;
    border-top: 1px solid #EDEDED;
    background-color: #fff;
}

@media screen and (min-width: 768px) {
    .dnd-flow {
        flex-direction: row;
    }
}